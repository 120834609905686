import { createSlice } from "@reduxjs/toolkit";
import { uniqueId } from "../../../utils/uniqueIdGenerator";

const initialState = {
  isSideBarOpen: true,
  isLoading: false,
  isBotBuildOpen: false,
  botLang: "en",
  examPapers: [
    {
      id: uniqueId(),
      name: "Maths",
      status: "Completed",
      time: "8m",
      questions: [
        {
          answer: "To manage and install third-party packages",
          options: [
            "To compile Python code into machine code",
            "To manage and install third-party packages",
            "To debug Python code",
            "To optimize Python code for performance",
          ],
          question:
            "What is the purpose of the `pip` package manager in Python?",
        },
        {
          answer: "Tuples are immutable, lists are mutable",
          options: [
            "Tuples are mutable, lists are immutable",
            "Tuples are immutable, lists are mutable",
            "Tuples are used for strings, lists are used for numbers",
            "Tuples are used for numbers, lists are used for strings",
          ],
          question:
            "What is the difference between `tuple` and `list` in Python?",
        },
        {
          answer: "To initialize an object's attributes",
          options: [
            "To define a class variable",
            "To initialize an object's attributes",
            "To define a class method",
            "To override a parent class method",
          ],
          question:
            "What is the purpose of the `__init__` method in a Python class?",
        },
        {
          answer: "Break exits the loop, continue skips to the next iteration",
          options: [
            "Break exits the loop, continue skips to the next iteration",
            "Break skips to the next iteration, continue exits the loop",
            "Break is used in for loops, continue is used in while loops",
            "Break is used in while loops, continue is used in for loops",
          ],
          question:
            "What is the difference between `break` and `continue` statements in Python?",
        },
        {
          answer: "To handle runtime errors and exceptions",
          options: [
            "To handle runtime errors and exceptions",
            "To optimize code for performance",
            "To debug code",
            "To compile code into machine code",
          ],
          question: "What is the purpose of the `try-except` block in Python?",
        },
        {
          answer: "== checks for equality, is checks for identity",
          options: [
            "== checks for equality, is checks for identity",
            "== checks for identity, is checks for equality",
            "== is used for strings, is is used for numbers",
            "== is used for numbers, is is used for strings",
          ],
          question:
            "What is the difference between `==` and `is` operators in Python?",
        },
        {
          answer: "To import a module or package",
          options: [
            "To define a new function",
            "To import a module or package",
            "To declare a variable",
            "To compile code into machine code",
          ],
          question: "What is the purpose of the `import` statement in Python?",
        },
        {
          answer: "range returns a list, xrange returns an iterator",
          options: [
            "range returns a list, xrange returns an iterator",
            "range returns an iterator, xrange returns a list",
            "range is used for integers, xrange is used for floats",
            "range is used for floats, xrange is used for integers",
          ],
          question:
            "What is the difference between `range` and `xrange` in Python?",
        },
        {
          answer: "To handle file I/O operations",
          options: [
            "To handle file I/O operations",
            "To manage threads and concurrency",
            "To optimize code for performance",
            "To debug code",
          ],
          question: "What is the purpose of the `with` statement in Python?",
        },
        {
          answer:
            "dict is a collection of key-value pairs, set is a collection of unique values",
          options: [
            "dict is a collection of key-value pairs, set is a collection of unique values",
            "dict is a collection of unique values, set is a collection of key-value pairs",
            "dict is used for strings, set is used for numbers",
            "dict is used for numbers, set is used for strings",
          ],
          question:
            "What is the difference between `dict` and `set` in Python?",
        },
      ],
    },
  ],
  totalExamScore: {
    totalMarks: 0,
    totalScore: 0,
    passingMarks: 0,
    examTitle: ''
  },
  certificateData:{
    userName: '',
    examTitle: '',
  },
  examResult:{
    attempts:0,
    resultId:0,
    certificateDate: 0
  }
};

const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    toggleSideBar(state, action) {
      state.isSideBarOpen = !state.isSideBarOpen;
    },
    setLoading(state, action) {
      state.isLoading = action.payload;
    },
    toggleBotBuild(state, action) {
      state.isBotBuildOpen = !state.isBotBuildOpen;
    },
    setBotLang(state, action) {
      state.botLang = action.payload;
    },
    createExamPaper(state, action) {
      state.examPapers.push(action.payload);
    },
    setTotalExamScore(state,action){
      state.totalExamScore.totalMarks = action.payload.totalMarks
      state.totalExamScore.totalScore = action.payload.totalScore
      state.totalExamScore.passingMarks = action.payload.passingMarks
      state.totalExamScore.examTitle = action.payload.examTitle
    },
    setCertificateData(state,action){
      state.certificateData.userName = action.payload.userName
      state.certificateData.examTitle = action.payload.examTitle
    },
    clearCertificateData(state,action){
      state.certificateData = {
        userName: '',
        examTitle: '',
      }
    },
    setExamResult(state,action){
      state.examResult.attempts = action.payload.remainingAttempts
      state.examResult.resultId = action.payload.examResultId
      state.examResult.certificateDate = action.payload.certificateDate
    }
  },
});

export const {
  toggleSideBar,
  setLoading,
  toggleBotBuild,
  setBotLang,
  createExamPaper,
  setTotalExamScore, setCertificateData, clearCertificateData, setExamResult
} = commonSlice.actions;
export default commonSlice.reducer;
