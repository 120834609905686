import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const documentApi = createApi({
  reducerPath: "documentApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://chatbot.samparkme.com",
    prepareHeaders: (headers) => {
      const token = JSON.parse(localStorage.getItem("userToken"));
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),

  endpoints: (builder) => ({
    //Upload document
    uploadDocument: builder.mutation({
      query: (data) => ({
        url: "document-management/document/upload/vector/document",
        method: "POST",
        body: data,
      }),
    }),

    //Get documents list
    getDocuments: builder.mutation({
      query: (botId) => ({
        url: "document-management/document/faq/document/list",
        method: "POST",
        body: {
          request: {
            requestData: {
              botId: botId,
            },
          },
        },
      }),
    }),

    //Delete document
    deleteDocument: builder.mutation({
      query: (data) => ({
        url: `document-management/document/faq/delete/document`,
        method: "POST",
        body: {
          request: {
            requestData: {
              documentId: data.id,
              aiServiceType: data.aiServiceType
            },
          },
        },
      }),
    }),
  }),
});

export const {
  useUploadDocumentMutation,
  useGetDocumentsMutation,
  useDeleteDocumentMutation,
} = documentApi;
