import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const generateAnswer = createApi({
  reducerPath: "generateAnswer",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://qa.samparkme.com/",
    prepareHeaders: (headers) => {
      const token = JSON.parse(localStorage.getItem("userToken"));
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    //QnA Api
    getAnswer: builder.mutation({
      query: ({ url, data }) => ({
        url: url,
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const { useGetAnswerMutation } = generateAnswer;
