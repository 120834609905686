import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { logout } from "../../redux/slice/auth/authSlice";
import samparkLogo from "../../assets/Images/SamparkLogo.png";
import samparkGlobLogo from "../../assets/Images/menu_burgur_icon.svg";
import { toggleSideBar } from "../../redux/slice/common/commonSlice";
import { GoWorkflow } from "react-icons/go";
import { FaShareNodes } from "react-icons/fa6";
import { LiaRobotSolid } from "react-icons/lia";
import { MdSpaceDashboard } from "react-icons/md";
import { FaUsers } from "react-icons/fa6";
import { IoNewspaperOutline } from "react-icons/io5";
import { RiTodoLine } from "react-icons/ri";
import Tooltip, { tooltipClasses }  from "@mui/material/Tooltip";
import departmentIcon from "../../assets/Images/department_icon.png";
import { GrCompliance } from "react-icons/gr";

const Sidebar = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("dashboard");
  const isSideBarOpen = useSelector((state) => state.common.isSideBarOpen);
  const dispatch = useDispatch();
  const location = useLocation();
  const userData = JSON.parse(localStorage.getItem("userData"));
  const botData = JSON.parse(sessionStorage.getItem("botData"));
  const effectiveRole = userData.role !== "ORG ADMIN" ? "USER" : "ORG ADMIN";
  const effectivePath = location.pathname;

  useEffect(() => {
    const path = location.pathname;
    if (path == "/") {
      setActiveTab("dashboard");
      return;
    } else {
      const tab = path.substring(1).split("/").at(-1);
      setActiveTab(tab);
    }
  }, [location.pathname]);

  const handleLogout = () => {
    localStorage.clear();
    dispatch(logout());
  };
  const sidebarToggler = () => {
    if (!isSideBarOpen) {
      dispatch(toggleSideBar());
    } else {
      navigate("/");
    }
  };

  return (
    <>
      <aside
        aria-label="Sidebar"
        className="Sidebar border-r-2 fixed"
        style={{
          width: isSideBarOpen ? "15%" : "5%",
          transition: "width 0.3s linear",
        }}
      >
        <div className="h-screen px-3 overflow-y-auto bg-gray-50 flex flex-col p-[10px]">
          <div className="flex items-center gap-2">
            <img
              src={isSideBarOpen ? samparkLogo : samparkGlobLogo}
              className="mx-auto pb-[10px] cursor-pointer"
              style={{
                width: isSideBarOpen ? "110px" : "33px",
              }}
              alt="samparkLogo"
              onClick={sidebarToggler}
            />
            {isSideBarOpen && (
              <img
                src={isSideBarOpen && samparkGlobLogo}
                className="mx-auto pb-[10px] cursor-pointer"
                style={{
                  width: isSideBarOpen && "33px",
                }}
                alt="samparkLogo"
                onClick={() => dispatch(toggleSideBar())}
              />
            )}
          </div>
          <span className="relative">
            <hr />
          </span>
          <div className="h-[92%] font-medium flex flex-col items-center justify-between">
            <div className="sidebar_left flex flex-col gap-2 mt-3">
              {effectivePath !== "/dashboard" &&
              effectivePath !== "/department/category" ? (
                <Tooltip
                  title={!isSideBarOpen && "Bots"}
                  arrow
                  placement="right"
                >
                  <span
                    className={`${
                      activeTab == "dashboard" ? "bg-gray-200 rounded-lg" : ""
                    }`}
                    onClick={() => {
                      setActiveTab("dashboard");
                    }}
                    style={{
                      width: isSideBarOpen ? "100%" : "35px",
                    }}
                  >
                    <Link
                      to="/"
                      className="flex items-center p-2 text-gray-900 rounded-lg group"
                    >
                      <LiaRobotSolid className="text-gray-600 w-5 h-5" />
                      {isSideBarOpen && (
                        <span className="ms-3 whitespace-nowrap group-hover:text-[#1EB733]">
                          Bots
                        </span>
                      )}
                    </Link>
                  </span>
                </Tooltip>
              ) : (
                <Tooltip
                  title={!isSideBarOpen && "Dashboard"}
                  arrow
                  placement="right"
                >
                  <span
                    className={`${
                      activeTab == "dashboard" ? "bg-gray-200 rounded-lg" : ""
                    }`}
                    onClick={() => {
                      setActiveTab("dashboard");
                    }}
                    style={{
                      width: isSideBarOpen ? "100%" : "35px",
                    }}
                  >
                    <Link
                      to="/dashboard"
                      className="flex items-center p-2 text-gray-900 rounded-lg group"
                    >
                      <MdSpaceDashboard className="text-gray-600 w-5 h-5" />
                      {isSideBarOpen && (
                        <span className="ms-3 whitespace-nowrap group-hover:text-[#1EB733]">
                          Dashboard
                        </span>
                      )}
                    </Link>
                  </span>
                </Tooltip>
              )}

              {effectiveRole === "ORG ADMIN" &&
                effectivePath !== "/dashboard" &&
                !botData && (
                  <Tooltip
                    title={!isSideBarOpen && "Users"}
                    arrow
                    placement="right"
                    sx={{
                      [`& .${tooltipClasses.tooltip}`]: {
                        maxWidth: 500,
                      },
                    }}
                  >
                    <span
                      className={`${
                        activeTab == "userdetails"
                          ? "bg-gray-200 rounded-lg"
                          : ""
                      } flex items-center justify-between`}
                      onClick={() => {
                        setActiveTab("userdetails");
                      }}
                      style={{
                        width: isSideBarOpen ? "100%" : "35px",
                      }}
                    >
                      <Link
                        to="/userdetails"
                        className="flex items-center justify-center p-2 text-gray-900 rounded-lg hover:text-[#1EB733]"
                      >
                        <FaUsers className="text-gray-600 w-5 h-5" />
                        {isSideBarOpen && (
                          <span className="ms-3 whitespace-nowrap">Users</span>
                        )}
                      </Link>
                    </span>
                  </Tooltip>
                )}

              {effectiveRole === "ORG ADMIN" &&
                effectivePath !== "/dashboard" &&
                !botData && (
                  <Tooltip
                    title={!isSideBarOpen && "Department"}
                    arrow
                    placement="right"
                  >
                    <span
                      className={`${
                        activeTab == "department"
                          ? "bg-gray-200 rounded-lg"
                          : ""
                      } flex items-center justify-between`}
                      onClick={() => {
                        setActiveTab("department");
                      }}
                      style={{
                        width: isSideBarOpen ? "100%" : "35px",
                      }}
                    >
                      <Link
                        to="/department"
                        className="flex items-center justify-center p-2 text-gray-900 rounded-lg hover:text-[#1EB733]"
                      >
                        {/* <FaUsers className="text-gray-600 w-5 h-5" /> */}
                        <img
                          src={departmentIcon}
                          alt="departmentIcon"
                          width={20}
                          height={20}
                        />
                        {isSideBarOpen && (
                          <span className="ms-3 whitespace-nowrap">
                            Department
                          </span>
                        )}
                      </Link>
                    </span>
                  </Tooltip>
                )}

              {botData && (
                <Tooltip
                  title={!isSideBarOpen && "Category"}
                  arrow
                  placement="right"
                >
                  <span
                    className={`${
                      activeTab == "category" ? "bg-gray-200 rounded-lg" : ""
                    } flex items-center justify-between`}
                    onClick={() => {
                      setActiveTab("category");
                    }}
                    style={{
                      width: isSideBarOpen ? "100%" : "35px",
                    }}
                  >
                    <Link
                      to="/department/category"
                      className="flex items-center justify-center p-2 text-gray-900 rounded-lg hover:text-[#1EB733]"
                    >
                      {/* <FaUsers className="text-gray-600 w-5 h-5" /> */}
                      <img
                        src={departmentIcon}
                        alt="departmentIcon"
                        width={20}
                        height={20}
                      />
                      {isSideBarOpen && (
                        <span className="ms-3 whitespace-nowrap">Category</span>
                      )}
                    </Link>
                  </span>
                </Tooltip>
              )}

              {/* <span
                className={`${
                  activeTab == "flow" ? "bg-gray-200 rounded-lg" : ""
                } flex items-center justify-between`}
                onClick={() => {
                  setActiveTab("flow");
                }}
                style={{
                  width: isSideBarOpen ? "100%" : "35px",
    
                }}
              >
                <Link
                  to="/create/flow"
                  className="flex items-center justify-center p-2 text-gray-900 rounded-lg"
                >
                  <GoWorkflow className="group-hover:text-[#1EB733]" />
                  {isSideBarOpen && (
                    <span className="ms-3 whitespace-nowrap group-hover:text-[#1EB733]">
                      Flows
                    </span>
                  )}
                </Link>
              </span>
              <span
                className={`${
                  activeTab == "intents" ? "bg-gray-200 rounded-lg" : ""
                } flex items-center justify-between`}
                onClick={() => {
                  setActiveTab("intents");
                }}
                style={{
                  width: isSideBarOpen ? "100%" : "35px",
    
                }}
              >
                <Link
                  to="/create/intents"
                  className="flex items-center justify-center p-2 text-gray-600 rounded-lg"
                >
                  <FaShareNodes className="group-hover:text-[#1EB733]" />
                  {isSideBarOpen && (
                    <span className="ms-3 whitespace-nowrap group-hover:text-[#1EB733]">
                      Intents
                    </span>
                  )}
                </Link>
              </span> */}

              {effectiveRole === "ORG ADMIN" &&
                effectivePath !== "/dashboard" &&
                !botData && (
                  <>
                    <Tooltip
                      title={!isSideBarOpen && "Topic List"}
                      arrow
                      placement="right"
                    >
                      <span
                        className={`${
                          activeTab == "topicList"
                            ? "bg-gray-200 rounded-lg"
                            : ""
                        } flex items-center justify-between`}
                        onClick={() => {
                          setActiveTab("topicList");
                        }}
                        style={{
                          width: isSideBarOpen ? "100%" : "35px",
                        }}
                      >
                        <Link
                          to="/topicList"
                          className="flex items-center justify-center p-2 text-gray-900 rounded-lg hover:text-[#1EB733]"
                        >
                          {/* <FaUsers className="text-gray-600 w-5 h-5" /> */}
                          {/* <img
                      src={departmentIcon}
                      alt="departmentIcon"
                      width={20}
                      height={20}
                    /> */}
                          <IoNewspaperOutline className="text-gray-600 w-5 h-5" />
                          {isSideBarOpen && (
                            <span className="ms-3 whitespace-nowrap">Papers</span>
                          )}
                        </Link>
                      </span>
                    </Tooltip>

                    <Tooltip
                      title={!isSideBarOpen && "All Exams"}
                      arrow
                      placement="right"
                    >
                      <span
                        className={`${
                          activeTab == "all-exams"
                            ? "bg-gray-200 rounded-lg"
                            : ""
                        } flex items-center justify-between`}
                        onClick={() => {
                          setActiveTab("all-exams");
                        }}
                        style={{
                          width: isSideBarOpen ? "100%" : "35px",
                        }}
                      >
                        <Link
                          to="/all-exams"
                          className="flex items-center justify-center p-2 text-gray-900 rounded-lg hover:text-[#1EB733]"
                        >
                          <RiTodoLine className="text-gray-600 w-5 h-5" />
                          {isSideBarOpen && (
                            <span className="ms-3 whitespace-nowrap">
                              All Exam
                            </span>
                          )}
                        </Link>
                      </span>
                    </Tooltip>

                    <Tooltip
                title={!isSideBarOpen && "Completed Exams"}
                arrow
                placement="right"
              >
                <span
                  className={`${
                    activeTab == "completed-exams" ? "bg-gray-200 rounded-lg" : ""
                  } flex items-center justify-between`}
                  onClick={() => {
                    setActiveTab("completed-exams");
                  }}
                  style={{
                    width: isSideBarOpen ? "100%" : "35px",
                  }}
                >
                  <Link
                    to="/completed-exams"
                    className="flex items-center justify-center p-2 text-gray-900 rounded-lg hover:text-[#1EB733]"
                  >
                    <GrCompliance className="text-gray-600 w-5 h-5" />
                    {isSideBarOpen && (
                      <span className="ms-3 whitespace-nowrap">Completed Exams</span>
                    )}
                  </Link>
                </span>
                   </Tooltip>
                  </>
                )}
            </div>

            {/* <div className="sidebar_right">
              <span>
                <hr />
              </span>
              <span>
                <Link
                  onClick={handleLogout}
                  to="/auth/*"
                  className="flex items-center p-2 text-gray-900 rounded-lg"
                >
                  <svg
                    className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 group-hover:text-[#1EB733]"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.96 2.96 0 0 0 .13 5H5Z" />
                    <path d="M6.737 11.061a2.961 2.961 0 0 1 .81-1.515l6.117-6.116A4.839 4.839 0 0 1 16 2.141V2a1.97 1.97 0 0 0-1.933-2H7v5a2 2 0 0 1-2 2H0v11a1.969 1.969 0 0 0 1.933 2h12.134A1.97 1.97 0 0 0 16 18v-3.093l-1.546 1.546c-.413.413-.94.695-1.513.81l-3.4.679a2.947 2.947 0 0 1-1.85-.227 2.96 2.96 0 0 1-1.635-3.257l.681-3.397Z" />
                    <path d="M8.961 16a.93.93 0 0 0 .189-.019l3.4-.679a.961.961 0 0 0 .49-.263l6.118-6.117a2.884 2.884 0 0 0-4.079-4.078l-6.117 6.117a.96.96 0 0 0-.263.491l-.679 3.4A.961.961 0 0 0 8.961 16Zm7.477-9.8a.958.958 0 0 1 .68-.281.961.961 0 0 1 .682 1.644l-.315.315-1.36-1.36.313-.318Zm-5.911 5.911 4.236-4.236 1.359 1.359-4.236 4.237-1.7.339.341-1.699Z" />
                  </svg>
                  {isSideBarOpen && (
                    <span className="ms-3 whitespace-nowrap group-hover:text-[#1EB733]">
                      Logout
                    </span>
                  )}
                </Link>
              </span>
            </div> */}
          </div>
        </div>
      </aside>
    </>
  );
};
export default Sidebar;
