import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const examApi = createApi({
  reducerPath: "examApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://chatbot.samparkme.com",
    prepareHeaders: (headers) => {
      const token = JSON.parse(localStorage.getItem("userToken"));
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),

  endpoints: (builder) => ({

     //create document url
     createDocumentUrl: builder.mutation({
        query: (data) => ({
          url: "/document-management/document/upload/files",
          method: "POST",
          body: data,
        }),
      }),


    //generate exam
    createExamPaper: builder.mutation({
      query: (data) => ({
        url: "/document-management/common/create/exam",
        method: "POST",
        body: data,
      }),
    }),

    //generate question and answers
    createQuestionAndAnswers: builder.mutation({
      query: (data) => ({
        url: "/document-management/common/create/exam/paper",
        method: "POST",
        body: data,
      }),
    }),

    //fetch exam details
    fetchExamDetails: builder.mutation({
      query: (id) => ({
        url: "/document-management/common/fetch/exam/details",
        method: "POST",
        body: {
          examPaperId: id,
        },
      }),
    }),

   //fetch all question papers
    fetchQuestionPaper: builder.mutation({
      query: (id) => ({
        url: "/document-management/common/fetch/exam/paper",
        method: "GET",
      }),
    }),

     //fetch all exam paper for table
     fetchExamPaper:builder.mutation({
      query: (id) => ({
        url: "/document-management/common/fetch/all/exams",
        method: "POST",
        body: {
          category: "",
          difficulty: "",
        },
      }),
    }),

    // fetchExamPaper: builder.mutation({
    //   query: (id) => ({
    //     url: "/document-management/common/fetch/all/exams",
    //     method: "GET",
    //   }),
    // }),

    //fetch question and answer by exam id
    fetchQustionAndAnswer: builder.mutation({
      query: (id) => ({
        url: "/document-management/common/fetch/question/paper",
        method: "POST",
        body: {
          examPaperId: id,
        },
      }),
    }),

    //fetch selected exam question and answer
    fetchSelectedExamQuestion: builder.mutation({
      query: (id) => ({
        url: "/document-management/common/fetch/exam/questions",
        method: "POST",
        body: {
          examDetailsId: id,
        },
      }),
    }),

    //delete created question papers
    deleteQuestionPaper: builder.mutation({
      query: (id) => ({
        url: `/document-management/common/delete/question/paper`,
        method: "POST",
        body: {
          examPaperId: id,
        },
      }),
    }),

    //fetch exams by question paper id
    fetchExamsByQuestionId: builder.mutation({
      query: (id) => ({
        url: "/document-management/common/fetch/examById",
        method: "POST",
        body: {
          examPaperId: id,
        },
      }),
    }),

    //delete exam papers
    deleteExam: builder.mutation({
      query: (id) => ({
        url: `/document-management/common/delete/exam/paper`,
        method: "POST",
        body: {
          examDetailsId: id,
        },
      }),
    }),

     //fetch selected exam question and answer
     fetchSelectedExamQuestion: builder.mutation({
      query: (id) => ({
        url: "/document-management/common/fetch/exam/questions",
        method: "POST",
        body: {
          examDetailsId: id,
        },
      }),
    }),

    //save exam result
    saveExamResult: builder.mutation({
      query: (data) => ({
        url: "/document-management/common/save/exam/result",
        method: "POST",
        body: data,
      }),
    }),

    //fetch Completed exams
    fetchCompletedExams: builder.mutation({
      query: (id) => ({
        url: "/document-management/common/fetch/completed/exam",
        method: "GET",
      }),
    }),

    //fetch exam result by exam id
    fetchExamResult: builder.mutation({
      query: (id) => ({
        url: "/document-management/common/fetch/exam/result",
        method: "POST",
        body: {
          examDetailsId: id,
        },
      }),
    }),

     //Upload document
     uploadQuestionsDocument: builder.mutation({
      query: (data) => ({
        url: "document-management/common/upload/excel/file",
        method: "POST",
        body: data,
      }),
    }),

  }),
});

export const {
    useFetchExamDetailsMutation,
    useFetchQustionAndAnswerMutation,
    useFetchExamPaperMutation,
    useCreateQuestionAndAnswersMutation,
    useCreateDocumentUrlMutation,
    useCreateExamPaperMutation,
    useFetchQuestionPaperMutation,
    useFetchSelectedExamQuestionMutation,
    useDeleteQuestionPaperMutation,
    useFetchExamsByQuestionIdMutation,
    useDeleteExamMutation,
    useSaveExamResultMutation,
    useFetchCompletedExamsMutation,
    useFetchExamResultMutation,
    useUploadQuestionsDocumentMutation,
} = examApi;

