import React, { useEffect, lazy, Suspense, useMemo, useRef } from "react";
import { Route, Routes, Navigate, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import ProtectedRoute from "./ProtectedRoute";
import Sidebar from "../components/common/Sidebar";
import Navbar from "../components/common/Navbar";
import Loader from "../components/common/Loader";

const Dashboard = lazy(() => import("../pages/dashboard/Dashboard"));
const ManageUser = lazy(() => import("../pages/ManageUser"));
const About = lazy(() => import("../pages/About"));
const UploadDocument = lazy(() => import("../pages/UploadDocument"));
const History = lazy(() => import("../components/user/History"));
const Flow = lazy(() => import("../pages/Flow"));
const Profile = lazy(() => import("../pages/Profile"));
const Intents = lazy(() => import("../pages/Intents"));
const CreateBot = lazy(() => import("../pages/dashboard/CreateBot"));
const Department = lazy(() => import("../pages/department/Department"));
const SubDepartment = lazy(() => import("../pages/department/SubDepartment"));
const ManageExams  = lazy(() => import("../pages/Exam/ManageExams"));
const QuestionsAndAnswers = lazy(() => import("../pages/Exam/QuestionsAndAnswers"));
const AllExams = lazy(() => import("../pages/Exam/AllExams"));
const ExamBot = lazy(() => import("../pages/Exam/ExamBot"));
const ScoreBoard = lazy(() => import("../pages/Exam/ScoreBoard"));
const CreateExam = lazy(()=> import("../pages/Exam/CreateExam"));
const CreatedExamsTable = lazy(() => import("../pages/Exam/CreatedExamsTable"));
const Certificate = lazy(() => import("../pages/Exam/Certificate"));
const CompletedExams = lazy(() => import("../pages/Exam/CompletedExams"));
const ExamResults = lazy(() => import("../pages/Exam/ExamResults"));

const AuthenticatedRoutes = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isSideBarOpen = useSelector((state) => state.common.isSideBarOpen);
  const currentPath = location.pathname;
  const selectedQuestionPaper = JSON.parse(sessionStorage.getItem("selectedQuestionPaper"))?.examPaperId;
  const botContainerRef = useRef(null);

  const sidebarWidth = useMemo(
    () => (isSideBarOpen ? "15%" : "5%"),
    [isSideBarOpen]
  );
  const mainWidth = useMemo(
    () => (isSideBarOpen ? "85%" : "95%"),
    [isSideBarOpen]
  );

  useEffect(() => {
    if (currentPath !== "/department/category") {
      sessionStorage.removeItem("department");
    }
    if (
      currentPath !== "/dashboard" &&
      currentPath !== "/department/category"
    ) {
      sessionStorage.removeItem("botData");
    }
    if (currentPath !== "/viewExams" && currentPath !== "/exam-preview" && currentPath !== "/create-exam") {
      sessionStorage.removeItem("selectedQuestionPaper");
    }
    if (
      currentPath !== "/exam-results" &&
      currentPath !== "/certificate"
    ) {
      sessionStorage.removeItem("selectedExam");
    }
  }, [location]);

   
  useEffect(() => {
    const myBotData = JSON.stringify({
      platform: "sampark_support_bot",
    });

    if (botContainerRef.current) {
      botContainerRef.current.setAttribute("arg", myBotData);
    }

    const script = document.createElement("script");
    script.src = "https://sambot.samparkme.com/bundle.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
    
  },[botContainerRef])



  return (
    <div className="flex h-screen">
      <div
        className="bg-gray-100"
        style={{ width: sidebarWidth, transition: "width 0.3s linear" }}
      >
        <Sidebar />
      </div>
      <div className="bg-gray-200 overflow-y-auto" style={{ width: mainWidth }}>
        <Navbar />
        <div className="flex flex-col mt-[50px]">
          <Suspense fallback={<Loader />}>
            <Routes>
              <Route path="/" element={<CreateBot />} />

              {/* Common routes for both ORG ADMIN and USER */}
              <Route
                element={
                  <ProtectedRoute requiredRole={["ORG ADMIN", "USER"]} />
                }
              >
                <Route path="/about" element={<About />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route
                  path="/department/category"
                  element={<SubDepartment />}
                />
                <Route path="/topicList" element={<ManageExams />}/>
                <Route path="/exam-preview" element={<QuestionsAndAnswers />}/>
                <Route path="/all-exams" element={<AllExams />}/>
                <Route path="/exam-bot" element={<ExamBot />}/>
                <Route path="/scoreboard" element={<ScoreBoard />}/>
                <Route path="/create-exam" element={<CreateExam />}/>
                <Route path="/viewExams" element={<CreatedExamsTable />}/>
                <Route path="/certificate" element={<Certificate />}/>
                <Route path="/completed-exams" element={<CompletedExams />}/>
                <Route path="/exam-results" element={<ExamResults />}/>
              </Route>

              {/* Routes for ORG ADMIN */}
              <Route element={<ProtectedRoute requiredRole={["ORG ADMIN"]} />}>
                <Route path="/userdetails" element={<ManageUser />} />
                <Route path="/upload/document" element={<UploadDocument />} />
                <Route path="/history" element={<History />} />
                <Route path="/create/flow" element={<Flow />} />
                <Route path="/create/intents" element={<Intents />} />
                <Route path="/department" element={<Department />} />
              </Route>

              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </Suspense>
        </div>
      </div>
      <div id="samparkWebBotContainer" ref={botContainerRef} style={{ position: "relative", zIndex: 999, display: currentPath === "/" ? "block" : "none" }}></div>
    </div>
  );
};

export default AuthenticatedRoutes;
